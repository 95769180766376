export const makeTwoDimensionArray = <T>(arr: T[], len: number) => {
  let chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
};

export const validateInput = (value: string, validation: RegExp) => {
  const regex = new RegExp(validation);
  return !regex.test(value);
};

export const getSumFileSizes = (files: File[]): number =>
  files.reduce((total, file) => (total += file.size), 0);

export const humanFileSize = (bytes: number) => {
  const thresh = 1000;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = ['kB', 'MB'];

  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};
