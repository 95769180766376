import React from 'react';
import { Layer, H3, Paragraph, Button } from '@lsg/components';
import { FootnotesRichtextRenderer } from '@lws/react-components/dist/components';
import { SubSection } from '@lsg/components/lib/components/atoms/Section/_SubSection';

export interface JobOfferLayerData {
  isOpen: boolean;
  content?: {
    jobTitle?: string;
    jobDescription?: Document;
  };
}

export interface JobOfferLayerProps extends JobOfferLayerData {
  toggleLayer: React.Dispatch<React.SetStateAction<JobOfferLayerData>>;
}
export const JobOfferLayer = ({
  isOpen,
  content,
  toggleLayer,
}: JobOfferLayerProps) => {
  return (
    <Layer
      aria-label="Job Post"
      open={isOpen}
      closeLinkLabel={'Zurück'}
      onCloseClick={() => toggleLayer({ isOpen: false })}
    >
      {content && (
        <>
          <H3>{content.jobTitle}</H3>
          <FootnotesRichtextRenderer
            wrapper={Paragraph}
            content={content.jobDescription as any}
          />
          <SubSection>
            <Button to={'/job-application/'}>Jetzt bewerben</Button>
          </SubSection>
        </>
      )}
    </Layer>
  );
};
