import React from 'react';
import styled from 'styled-components';
import Dropzone, { DropzoneState } from 'react-dropzone';
import { CircleIconLink, Icon } from '@lsg/components';

type UploadProps = {
  onUpload: (acceptedFiles: File[], rejectedFiles: File[]) => Promise<any>;
};

const FileUploadComponent = ({ onUpload }: UploadProps) => {
  return (
    <>
      <Dropzone
        onDrop={onUpload}
        accept={['application/pdf', 'image/png', 'image/jpg']}
      >
        {({ getRootProps, getInputProps }: DropzoneState) => (
          <DropzoneArea {...getRootProps()}>
            <FileInput {...getInputProps()} />
            <CircleIconLink
              label={'Laden Sie Ihre Dokumente hier hoch'}
              helper={'Oder ziehen Sie die Dokumente einfach in dieses Fenster'}
              iconName={Icon.Names.interaction___upload as any}
              onClick={() => {}}
            />
          </DropzoneArea>
        )}
      </Dropzone>
    </>
  );
};

export default FileUploadComponent;

const DropzoneArea = styled.div`
  padding: 0;
  width: fit-content;
  margin-top: 32px;
  cursor: pointer !important;
  &:focus {
    outline: none;
  }
`;

const FileInput = styled.input``;
