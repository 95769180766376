import * as React from 'react';
import { Section, Grid } from '@lsg/components';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { LwsComponentProperties } from '@lws/page-components/dist/components/types';
import { graphql } from 'gatsby';
import {
  FactComponent,
  FactComponentData,
} from '@lws/page-components/dist/components/FactsComponent/FactComponent';

// TODO(benjamin):
// For better distinction between Facts and Fact,
// this component should be renamed to e.g. FactsWrapperComponent
export interface FactsComponentData {
  __typename?: 'ContentfulFacts';
  factsList: FactComponentData[];
}

export const FactsComponent = ({
  data,
}: LwsComponentProperties<FactsComponentData>) => {
  const factsList = data.array('factsList');
  return (
    <Section>
      <Grid verticalSpacing={Grid.Spacing?.CONTAINER}>
        <GridRow gap={Grid.Spacing?.CONTAINER}>
          {factsList.map((fact, idx) => {
            return (
              <GridColumn xs={12} sm={4} key={`icon-with-label-${idx}`}>
                <FactComponent data={fact} />
              </GridColumn>
            );
          })}
        </GridRow>
      </Grid>
    </Section>
  );
};

export const FactsFragment = graphql`
  fragment FactsFragment on ContentfulFacts {
    factsList {
      ...FactFragment
    }
    navigationName
  }
`;
