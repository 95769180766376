import React from 'react';
import FileUploadComponent from '../FileUploadComponent/FileUploadComponent';
import { Section, TextSection, Headline, Paragraph } from '@lsg/components';

type FileUploadFieldProps = {
  fileUploadValues: File[];
  onUploadFile: (acceptedFiles: File[], rejectedFiles: File[]) => Promise<void>;
};

const FileUploadField = ({ onUploadFile }: FileUploadFieldProps) => {
  return (
    <Section>
      <TextSection
        title={'Welche Dokumente wir benötigen'}
        titleSize={Headline.Sizes?.H3}
      >
        <Paragraph>
          Wir interessieren uns für Ihre beruflichen Schwerpunkte und sind
          neugierig auf Ihren persönlichen Werdegang. Um ein besseres Bild zu
          bekommen, schicken Sie uns bitte ein Anschreiben mit
          Gehaltsvorstellung und möglichem Arbeitsbeginn, sowie Lebenslauf und
          Ihre Zeugnisse.
        </Paragraph>
        <Paragraph>
          Wir können PDF, PNG und JPG Dateien in einer Gesamtgröße von 25MB
          empfangen.
        </Paragraph>
      </TextSection>

      <FileUploadComponent
        onUpload={(acceptedFiles, rejectedFiles) =>
          onUploadFile(acceptedFiles, rejectedFiles)
        }
      />
    </Section>
  );
};

export default FileUploadField;
