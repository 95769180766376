import React from 'react';
import { Document } from '@contentful/rich-text-types';
import { LsgPicture, AssetAccessor, AssetType } from '@lws/react-components';
import { LwsComponentProperties } from '@lws/page-components/dist/components/types';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { Grid, Section } from '@lsg/components';
import { Looks } from '@lsg/components/lib/components/atoms/Section/Section';
import styled from 'styled-components';

export interface MediaComponentData {
  __typename?: 'ContentfulMedia';
  internalTitle?: string;
  media: AssetAccessor;
  caption?: Document;
}

export const MediaComponent = ({
  data,
}: LwsComponentProperties<MediaComponentData>) => {
  const media = data.asset('media');

  if (media.type() === AssetType.Video) {
    // TODO(benjamin): Handle this case
    return <></>;
  }

  if (media.type() === AssetType.Image) {
    return (
      <CustomSection>
        <Grid>
          <GridRow columnVariate={true}>
            <GridColumn size={12}>
              <LsgPicture data={media} />
            </GridColumn>
          </GridRow>
        </Grid>
      </CustomSection>
    );
  }

  // NOTE(benjamin): What's the sane default here? N/A image?
  return <></>;
};

export const CustomSection = styled(Section)`
  margin: 0 0 !important;
`;
