import React from 'react';
import { Section } from '@lsg/components';
import { LwsComponentProperties } from '@lws/page-components/dist/components/types';
import { TeaserData } from '@lws/page-components';
import { graphql } from 'gatsby';
import { TeaserComponent } from './TeaserComponent';
import { NavigationAnchor } from '../NavigationAnchor/NavigationAnchor';

interface TeaserSectionProps {
  separatorBottom: boolean;
  fullWidth: boolean;
  navigationName: string;
  orderInverted: boolean;
  teaser: TeaserData;
}
const TeaserSection = ({
  data,
}: LwsComponentProperties<TeaserSectionProps>) => {
  const separatorBottom = data.boolean('separatorBottom');
  const fullWidth = data.boolean('fullWidth');
  const orderInverted = data.boolean('orderInverted');
  const navigationName = data.slug('navigationName');
  const teaser = data.field('teaser');
  return (
    <>
      <NavigationAnchor id={navigationName} />
      <Section separatorBottom={!!separatorBottom} fullWidth={!!fullWidth}>
        <TeaserComponent
          data={teaser}
          orderInverted={orderInverted}
          isCta={false}
        />
      </Section>
    </>
  );
};

export default TeaserSection;

export const TeaserSectionFragment = graphql`
  fragment TeaserSectionFragment on ContentfulTeaserSection {
    separatorBottom
    orderInverted
    navigationName
    fullWidth
    teaser {
      ...TeaserFragment
    }
  }
`;
