import React, { Component } from 'react';
import { graphql } from 'gatsby';
import {
  StaticOrPreviewContent,
  ContentAccessor,
  GraphQLContentAccessor,
  ContentfulContentAccessor,
} from '@lws/react-components';
import {
  StageComponent,
  SectionComponent,
  PageContentList,
} from '@lws/page-components';
import { Headline } from '@lsg/components';

import { ContentfulPage, ContentfulSeoData } from '../generated/graphql-types';

import { getEntryByContentType } from '../utils/contentful';
import { ViewportContext } from '@lws/page-components/dist/utils';
import { Layout } from '../components/layout';
import TeaserSection from '../components/TeaserSection/TeaserSection';
import { JobOfferList } from '../components/JobOfferList/JobOfferList';
import { BenefitsSection } from '../components/BenefitsSection';
import { FactsComponent } from '../components/FactsComponent/FactsComponent';
import { MediaComponent } from '../components/MediaComponent';
import JobOfferForm from '../components/JobOfferForm/JobOfferForm';
import { FaqSectionComponent } from '../components/FaqSectionComponent/FaqSectionComponent';

export interface PageProps {
  page: ContentAccessor<ContentfulPage>;
}

export interface PageTemplateProps {
  data: {
    contentfulPage: ContentfulPage;
  };
  pageContext: {
    title: string;
    url: string;
    contentful_id: string;
  };
}

// interface AllowedComponentTypes =
function render(entry: PageProps) {
  const content = entry.page.array('content') as any;

  const mappedSections = {
    ContentfulStage: StageComponent,
    ContentfulBenefitsSection: BenefitsSection,
    ContentfulTeaserSection: TeaserSection,
    ContentfulSection: SectionComponent,
    ContentfulFacts: FactsComponent,
    ContentfulFaqSection: FaqSectionComponent,
    ContentfulJobOfferList: JobOfferList,
    ContentfulMedia: MediaComponent,
    ContentfulJobOfferForm: JobOfferForm,
  };

  if (!content) {
    return <Headline>Error: No content found.</Headline>;
  }
  return <PageContentList content={content} componentMap={mappedSections} />;
}

class PageTemplate extends Component<PageTemplateProps> {
  render() {
    const accessorData = new GraphQLContentAccessor(this.props.data);
    const pageProps: PageProps = {
      page: accessorData.field('contentfulPage'),
    };
    const metadata = accessorData
      .field('contentfulPage')
      .field('pageMetadata')
      .raw() as ContentfulSeoData;
    const sections = accessorData.field('contentfulPage').array('content');

    const navigationNames: string[] = sections
      .filter(
        (section: ContentAccessor<any>) => !!section.text('navigationName')
      )
      .map((section: ContentAccessor<any>) => section.text('navigationName'));

    return (
      <ViewportContext.Consumer>
        {value => (
          <Layout
            key={value}
            navigationNames={navigationNames}
            pageMetadata={metadata}
          >
            <StaticOrPreviewContent
              render={render}
              staticData={pageProps}
              getPreviewData={async () => {
                const page = await getEntryByContentType<ContentfulPage>(
                  this.props.pageContext.contentful_id,
                  'Page'
                );

                if (!page) return pageProps;

                return {
                  location,
                  page: new ContentfulContentAccessor(page),
                } as PageProps;
              }}
            />
          </Layout>
        )}
      </ViewportContext.Consumer>
    );
  }
}

export default PageTemplate;

// query database to populate props.data
export const query = graphql`
  query($url: String!) {
    contentfulPage(url: { eq: $url }) {
      pageMetadata {
        ...SeoDataFragment
      }
      content {
        ...PageContentFragment
      }
    }
  }
`;
