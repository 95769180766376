export const MAX_FILESIZE = 25000000;

export const Errors = {
  required: (name: string) => `Bitte geben Sie Ihren ${name} ein`,
  missingFiles: 'Noch keine Dokumente hochgeladen',
  wrongFileType: 'Bitte wählen Sie eine Datei im Format PDF, PNG oder JPG aus',
  excessFilesSize: 'Die ausgewählte Datei ist zu groß',
};

export const inputFields = [
  {
    fieldName: 'Name',
  },
  {
    fieldName: 'Nachname',
  },
  {
    fieldName: 'E-Mail',
    validation: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    errorMessage: 'Bitte geben Sie eine gültige E-Mail an',
  },
  {
    fieldName: 'Telefonnummer',
  },
];

export const tableColumns = [
  { title: 'Dateiname', name: 'name' },
  { title: 'Format', name: 'type' },
  { title: 'Größe', name: 'size' },
  { title: '', name: 'icon' },
];
