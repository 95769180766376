import React, { Component } from 'react';
import {
  validateInput,
  getSumFileSizes,
  humanFileSize,
} from '../../utils/functions';
import InputFormFields from './InputFormFields';
import FileUploadField from './FileUploadField';
import FormSuccess from './FormSuccess';
import { JobOfferFormState, InputType } from './JobOfferFormTypes';
import {
  ComplexTable,
  Section,
  Button,
  Paragraph,
  Icon,
  IconLink,
} from '@lsg/components';
import {
  IComplexTableColumnProperties,
  IComplexTableRow,
} from '@lsg/components/lib/components/molecules/ComplexTable/ComplexTable';
import { Spinner } from './LoadingSpinner';
import styled from 'styled-components';
import {
  inputFields,
  Errors,
  MAX_FILESIZE,
  tableColumns,
} from './formConstants';

export const getInitialFormInputValues = (formFields: InputType[]) =>
  formFields
    .map((field: InputType) => ({
      [field.fieldName]: {
        value: '',
      },
    }))
    .reduce((initialState, input) => {
      return Object.assign(initialState, input);
    }, {});
export default class JobOfferForm extends Component<{}, JobOfferFormState> {
  public formFields: InputType[] = inputFields;
  public tableColumns: IComplexTableColumnProperties[] = tableColumns;
  constructor(props: {}) {
    super(props);

    this.state = {
      formInputValues: getInitialFormInputValues(this.formFields),
      fileUploadValues: {
        files: [],
      },
      formState: {},
    };
  }

  onChangeInputField = (value: string, input: InputType) => {
    const { formInputValues } = this.state;
    this.setState({
      ...this.state,
      formInputValues: {
        ...formInputValues,
        [input.fieldName]: {
          value,
          error: false,
        },
      },
    });
  };

  onBlurInputField = ({ fieldName, validation }: InputType) => {
    const { formInputValues } = this.state;
    if (validation && !!formInputValues[fieldName].value) {
      this.setState({
        ...this.state,
        formInputValues: {
          ...formInputValues,
          [fieldName]: {
            ...formInputValues[fieldName],
            error: validateInput(formInputValues[fieldName].value, validation),
          },
        },
      });
    }
  };

  onUploadFile = (
    acceptedFiles: File[],
    rejectedFiles: File[]
  ): Promise<void> => {
    const { fileUploadValues } = this.state;
    this.setState({
      ...this.state,
      formState: {
        isFormWaiting: true,
      },
    });

    const fileTypeIsRejected = !!rejectedFiles.length;
    if (fileTypeIsRejected) {
      return new Promise(resolve =>
        resolve(
          this.setState({
            ...this.state,
            fileUploadValues: {
              ...this.state.fileUploadValues,
              error: true,
              errorMessage: Errors.wrongFileType,
            },
          })
        )
      );
    }

    const filesSize = getSumFileSizes([
      ...fileUploadValues.files,
      acceptedFiles[0],
    ]);

    if (filesSize > MAX_FILESIZE) {
      return new Promise(resolve =>
        resolve(
          this.setState({
            ...this.state,
            formState: {
              isFormWaiting: false,
            },
            fileUploadValues: {
              ...fileUploadValues,
              error: true,
              errorMessage: Errors.excessFilesSize,
            },
          })
        )
      );
    }
    return new Promise(resolve =>
      resolve(
        this.setState({
          ...this.state,
          fileUploadValues: {
            files: [...fileUploadValues.files, acceptedFiles[0]],
          },
          formState: {
            isFormWaiting: false,
          },
        })
      )
    );
  };

  onRemoveFile = (rowIndex: number): Promise<void> => {
    const { fileUploadValues } = this.state;
    return new Promise(resolve =>
      resolve(
        this.setState({
          ...this.state,
          fileUploadValues: {
            files: fileUploadValues.files.filter(
              (file, index) => index !== rowIndex
            ),
          },
        })
      )
    );
  };

  hasFormError = (): boolean => {
    const { formInputValues, fileUploadValues } = this.state;
    // validation required fields
    const formFieldsAreMissingOrHaveErrors = Object.keys(formInputValues).some(
      key => !formInputValues[key].value.length || formInputValues[key].error
    );
    const filesAreMissing = !fileUploadValues.files.length;
    return formFieldsAreMissingOrHaveErrors || filesAreMissing;
  };

  onFormSubmit = () => {
    const { formInputValues, fileUploadValues } = this.state;
    if (this.hasFormError()) {
      return this.setState({
        ...this.state,
        formState: {
          error: true,
        },
        fileUploadValues: {
          files: fileUploadValues.files,
          error: true,
          errorMessage:
            (!fileUploadValues.files.length && Errors.missingFiles) || '',
        },
      });
    }
    const formData = new FormData();
    Object.keys(formInputValues).forEach(key =>
      formData.append(key, formInputValues[key].value)
    );
    fileUploadValues.files.forEach(file =>
      formData.append('files[]', file, file.name)
    );
    // set waiting/loading state
    this.setState({
      ...this.state,
      formState: { ...this.state.formState, isFormWaiting: true },
    });

    fetch('/jobForm/', { method: 'POST', body: formData })
      .then(res => {
        if (res.status !== 200) {
          throw new Error(
            'Antwort vom Server: "' +
              res.statusText +
              '". Bitte ersuchen Sie es später erneut!'
          );
        } else {
          this.setState({
            ...this.state,
            formState: {
              ...this.state.formState,
              isFormWaiting: false,
              isRequestSuccess: true,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          ...this.state,
          formState: { error: true },
          fileUploadValues: {
            files: fileUploadValues.files,
            error: true,
            errorMessage: `${err.message}. Bitte versuchen Sie es später erneut!`,
          },
        });
      });
  };

  renderTableIcons = (): IComplexTableRow[] => {
    return this.state.fileUploadValues.files.map((file, idx) => {
      return {
        rowData: this.tableColumns.map(col => {
          switch (col.name) {
            case 'size':
              return humanFileSize(file[col.name]);
            case 'type':
              const fileExtension = file[col.name].split('/');
              return `.${fileExtension.pop()}`;
            case 'icon':
              return (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.onRemoveFile(idx)}
                >
                  <IconLink
                    iconName={Icon.Names.interaction___trash}
                    look={IconLink.Looks?.NO_TEXT}
                    color={IconLink.Colors?.SECONDARY}
                  />
                </div>
              );
            default:
              return file[col.name];
          }
        }),
      };
    });
  };

  render() {
    const { fileUploadValues, formInputValues, formState } = this.state;
    return formState.isRequestSuccess ? (
      <FormSuccess />
    ) : (
      <>
        <InputFormFields
          formInputValues={formInputValues}
          formState={formState}
          onChangeInputField={this.onChangeInputField}
          onBlurInputField={this.onBlurInputField}
          formFields={inputFields}
        />
        <FileUploadField
          fileUploadValues={fileUploadValues.files}
          onUploadFile={this.onUploadFile}
        />
        <Section>
          <ComplexTable
            columnProperties={this.tableColumns}
            tableBodyData={this.renderTableIcons()}
          />
          <FormStatusContainer>
            {formState.isFormWaiting && <Spinner />}
          </FormStatusContainer>
          {
            <TextHolder hasError={!!fileUploadValues.error}>
              <Paragraph size={Paragraph.Sizes?.HELPER}>
                {fileUploadValues.errorMessage}
              </Paragraph>
            </TextHolder>
          }
          {!fileUploadValues.files.length && !fileUploadValues.error && (
            <Paragraph size={Paragraph.Sizes?.HELPER}>
              Noch keine Dokumente hochgeladen
            </Paragraph>
          )}
        </Section>
        <Section>
          <ButtonContainer>
            <Button onClick={this.onFormSubmit}>Senden</Button>
          </ButtonContainer>
        </Section>
      </>
    );
  }
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FormStatusContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
`;

const TextHolder = styled.div`
  p {
    color: ${({ hasError }: { hasError: boolean }) => hasError && '#db1818'};
    margin: 0;
    margin-top: 25px;
  }
`;
