import React from 'react';
import {
  Grid,
  Paragraph,
  Button,
  Section,
  TextSection,
  Headline,
  IconLink,
  Icon,
} from '@lsg/components';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { LsgPicture, ContentAccessor } from '@lws/react-components';
import { TeaserData } from '@lws/page-components';

export interface TeaserComponentProps {
  data: ContentAccessor<TeaserData>;
  isCta?: boolean;
  orderInverted?: boolean;
}

export const TeaserComponent = ({
  data,
  isCta = true,
  orderInverted = false,
}: TeaserComponentProps) => {
  const title = data.text('title');
  const label = data.text('label');
  const description = data.longText('description');
  const ctaText = data.text('ctaText');
  const image = data.asset('image');
  const linkUrl = data.field('link').text('url');
  const viewportSize = useViewportSize();
  return (
    <Section>
      <Grid verticalSpacing={Grid.Spacing?.CONTAINER}>
        <GridRow
          verticalAlign={Grid.VerticalAlign?.MIDDLE}
          gap={Grid.Spacing?.SECTION}
        >
          {viewportSize === 'sm' || viewportSize === 'xs' || orderInverted ? (
            <>
              <GridColumn md={4}>
                <LsgPicture data={image} />
              </GridColumn>
              <GridColumn md={8}>
                <TextSection
                  label={label}
                  title={title}
                  titleSize={Headline.Sizes?.H2}
                />
                <Paragraph>{description}</Paragraph>
                {isCta ? (
                  <Button to={`${linkUrl}/`}>{ctaText}</Button>
                ) : (
                  <IconLink
                    to={`${linkUrl}/`}
                    iconName={Icon.Names.interaction_arrows_arrowRight}
                  >
                    {ctaText}
                  </IconLink>
                )}
              </GridColumn>
            </>
          ) : (
            <>
              <GridColumn md={8}>
                <TextSection
                  label={label}
                  title={title}
                  titleSize={Headline.Sizes?.H2}
                />
                <Paragraph>{description}</Paragraph>
                {isCta ? (
                  <Button to={`${linkUrl}/`}>{ctaText}</Button>
                ) : (
                  <IconLink
                    to={`${linkUrl}/`}
                    iconName={Icon.Names.interaction_arrows_arrowRight}
                  >
                    {ctaText}
                  </IconLink>
                )}
              </GridColumn>
              <GridColumn md={4}>
                <LsgPicture data={image} />
              </GridColumn>
            </>
          )}
        </GridRow>
      </Grid>
    </Section>
  );
};

import { graphql } from 'gatsby';
import {
  ViewportContext,
  useViewportSize,
} from '@lws/page-components/dist/utils';

export const TeaserFragment = graphql`
  fragment TeaserFragment on ContentfulTeaser {
    __typename
    title
    label
    description {
      description
    }
    ctaText
    image {
      ...ContentfulAssetFragment_downloadLocal
    }
    link {
      url
    }
  }
`;
