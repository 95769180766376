import React from 'react';
import { LwsComponentProperties } from '@lws/page-components/dist/components/types';
import {
  Section,
  Grid,
  Accordion,
  Paragraph,
  AccordionGroup,
  TextSection,
} from '@lsg/components';
import {
  Spacing,
  VerticalAlign,
} from '@lsg/components/lib/components/atoms/Grid/Grid';
import { Document } from '@contentful/rich-text-types';
import { emptyDocument } from '@lws/page-components/dist/utils';
import { FootnotesRichtextRenderer } from '@lws/react-components';
import { graphql } from 'gatsby';

export interface QuestionAnswerPair {
  question: string;
  answer: string;
}

export interface FaqSectionComponentData {
  entries: Array<{
    question: {
      question: string;
    };
    answer: Document;
  }>;
}

export const FaqSectionComponent = ({
  data,
}: LwsComponentProperties<FaqSectionComponentData>) => {
  const faqEntries = data.array('entries');

  const accordions = faqEntries.map((faq, index) => {
    const question = faq.longText('question', '');
    const answer = faq.richText('answer', emptyDocument);

    return (
      <Accordion title={question} key={`faq-${index}`}>
        <TextSection>
          <FootnotesRichtextRenderer content={answer} wrapper={Paragraph} />
        </TextSection>
      </Accordion>
    );
  });

  return (
    <Section fullWidth={false}>
      <Grid>
        <Grid.Row
          gap={Spacing.HALF_SECTION}
          verticalAlign={VerticalAlign.MIDDLE}
        >
          <Grid.Column>
            <AccordionGroup.Stateful>{accordions}</AccordionGroup.Stateful>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  );
};

export const FaqSectionFragment = graphql`
  fragment FaqSectionFragment on ContentfulFaqSection {
    entries {
      question {
        question
      }
      answer {
        json
      }
    }
  }
`;
