import React from 'react';
import { Section, Grid, Paragraph, TextSection } from '@lsg/components';
import { LsgPicture, FootnotesRichtextRenderer } from '@lws/react-components';
import { SubSection } from '@lsg/components/lib/components/atoms/Section/_SubSection';
import { VerticalAlign } from '@lsg/components/lib/components/atoms/Grid/Grid';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { graphql } from 'gatsby';
import { LwsSectionProperties } from '@lws/page-components/dist/components/types';
import { SpanElement } from '@lws/page-components/dist/components/StageComponent/StageHeadline';
import { ContentfulBenefitsSection } from '../../generated/graphql-types';
import { BenefitsList } from '@lws/page-components/dist/components/BenefitsSection/BenefitsList';
import {
  emptyDocument,
  useViewportSize,
} from '@lws/page-components/dist/utils';
import { NavigationAnchor } from '../NavigationAnchor/NavigationAnchor';

export const BenefitsSection = ({
  data,
  id,
}: LwsSectionProperties<ContentfulBenefitsSection>) => {
  const headline = data.richText('headline', emptyDocument);
  const overline = data.text('overline');
  const navigationName = data.slug('navigationName');
  const leadText = data.richText('leadText', emptyDocument);
  const picture = data.asset('picture');
  const benefitsList = data.array('benefitsList');
  const separatorBottom = data.boolean('separatorBottom', true);

  const viewport = useViewportSize();
  const reverseOrder = viewport === 'xs' || viewport === 'sm';

  const imgOrder = reverseOrder ? 1 : 2;
  const textOrder = reverseOrder ? 2 : 1;

  return (
    <>
      <NavigationAnchor id={navigationName || id} />
      <Section separatorBottom={separatorBottom} fullWidth={false}>
        <Grid verticalSpacing={Grid.Spacing?.CONTAINER}>
          <GridRow>
            <GridColumn xl={6} lg={6} md={6} sm={12} xs={12} order={textOrder}>
              <TextSection
                label={overline}
                title={
                  <FootnotesRichtextRenderer
                    content={headline}
                    wrapper={SpanElement}
                  />
                }
              >
                <FootnotesRichtextRenderer
                  content={leadText}
                  wrapper={Paragraph}
                />
              </TextSection>

              <SubSection>
                <BenefitsList data={benefitsList} />
              </SubSection>
            </GridColumn>

            {picture && (
              <GridColumn
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                verticalAlign={VerticalAlign.MIDDLE}
                order={imgOrder}
              >
                <LsgPicture
                  data={picture}
                  maxWidth={400}
                  style={{ margin: 'auto' }}
                />
              </GridColumn>
            )}
          </GridRow>
        </Grid>
      </Section>
    </>
  );
};

export const BenefitsSectionFragment = graphql`
  fragment BenefitsSectionFragment on ContentfulBenefitsSection {
    __typename
    internalTitle
    headline {
      json
    }
    overline
    leadText {
      json
    }
    picture {
      ...ContentfulAssetFragment_downloadLocal
    }
    benefitsList {
      ...BenefitFragment
    }
    separatorBottom
    navigationName
  }
`;
