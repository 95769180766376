import React, { useState } from 'react';
import { Section, H3, ClickList } from '@lsg/components';
import { LwsComponentProperties } from '@lws/page-components/dist/components/types';
import { graphql } from 'gatsby';
import { Document } from '@contentful/rich-text-types';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { JobOfferLayer, JobOfferLayerData } from './JobOfferLayer';
import { IClickListRowProps } from '@lsg/components/lib/components/molecules/ClickList/ClickList';

const ClickListRow = ClickList.Row as React.SFC<IClickListRowProps>;
interface JobProfileData {
  jobTitle: string;
  jobUrl: string;
  jobDescription: Document;
}
interface JobOfferListData {
  jobOffersList: JobProfileData[];
}
export const JobOfferList = (
  props: LwsComponentProperties<JobOfferListData>
) => {
  const { data } = props;
  const jobOffers = data.array('jobOffersList');
  const [layer, toggleLayer] = useState({
    isOpen: false,
    content: undefined,
  } as JobOfferLayerData);
  return (
    <Section>
      <H3>Offene Stellen</H3>
      <GridRow>
        <GridColumn xs={12} sm={9}>
          <ClickList>
            {jobOffers.map(jobOffer => {
              const jobTitle = jobOffer.text('jobTitle');
              const jobUrl = jobOffer.slug('jobUrl');
              const jobDescription = jobOffer.richText('jobDescription');

              return (
                <ClickListRow
                  key={jobTitle}
                  title={jobTitle}
                  onClick={(e: any) => {
                    e.preventDefault();
                    toggleLayer({
                      isOpen: true,
                      content: { jobTitle, jobDescription },
                    } as JobOfferLayerData);
                  }}
                  href={`/jobs/${jobUrl}/`}
                />
              );
            })}
          </ClickList>
        </GridColumn>
      </GridRow>
      <JobOfferLayer
        isOpen={layer.isOpen}
        content={layer.content}
        toggleLayer={toggleLayer}
      />
    </Section>
  );
};

export const JobOfferListFragment = graphql`
  fragment JobOfferListFragment on ContentfulJobOfferList {
    jobOffersList {
      jobTitle
      jobUrl
      jobDescription {
        json
      }
    }
  }
`;
