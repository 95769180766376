import React from 'react';
import { makeTwoDimensionArray } from '../../utils/functions';
import { GridRow } from '@lsg/components/lib/components/atoms/Grid/GridRow';
import {
  Grid,
  InputTextfield,
  Section,
  TextSection,
  Headline,
  Paragraph,
} from '@lsg/components';
import { GridColumn } from '@lsg/components/lib/components/atoms/Grid/GridColumn';
import { FormInputValuesType, InputType } from './JobOfferFormTypes';
import { Errors } from './formConstants';

type InputFormFieldsProps = {
  formInputValues: FormInputValuesType;
  formState: any;
  onChangeInputField: (value: string, input: InputType) => void;
  onBlurInputField: (input: InputType) => void;
  formFields: InputType[];
};

const InputFormFields = ({
  formInputValues,
  formState,
  onChangeInputField,
  onBlurInputField,
  formFields,
}: InputFormFieldsProps) => {
  return (
    <Section>
      <TextSection
        label={'Danke für Ihr Interesse'}
        title={'Ihre Kontaktdaten'}
        titleSize={Headline.Sizes?.H3}
      >
        <Paragraph>
          Bitte hinterlassen Sie uns einige Angaben zu Ihrer Person, damit wir
          Kontakt aufnehmen können.
        </Paragraph>
      </TextSection>
      <Grid verticalSpacing={Grid.Spacing?.GALLERY}>
        {makeTwoDimensionArray(formFields, 2).map((row, idx) => (
          <GridRow key={`input-row-${idx}`} gap={Grid.Spacing?.GALLERY}>
            {row.map(
              ({ fieldName, validation, errorMessage }: InputType, i) => {
                const isRequiredError =
                  formInputValues[fieldName].value.length === 0 &&
                  formState.error;
                const isValidationError = formInputValues[fieldName].error;

                return (
                  <GridColumn key={`input-col-${idx}-${i}`} sm={6}>
                    <InputTextfield
                      name={fieldName}
                      label={fieldName}
                      value={formInputValues[fieldName].value}
                      onChange={value =>
                        onChangeInputField(value as string, { fieldName })
                      }
                      onBlur={fieldName =>
                        onBlurInputField({ fieldName, validation })
                      }
                      invalid={isValidationError || isRequiredError}
                      errorText={
                        isValidationError
                          ? errorMessage
                          : isRequiredError
                          ? Errors.required(fieldName)
                          : ''
                      }
                    />
                    <div />
                  </GridColumn>
                );
              }
            )}
          </GridRow>
        ))}
      </Grid>
    </Section>
  );
};

export default InputFormFields;
