import React from 'react';
import styled from 'styled-components';

export const Spinner = () => (
  <StyledSpinner id="loading-spinner">
    <svg
      className="circle__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="circle__svg-circle" cx="50" cy="50" r="45" />
    </svg>
  </StyledSpinner>
);

const StyledSpinner = styled.div`
  .circle__svg {
    animation: 1.5s linear infinite both circle__svg;
    height: 48px;
  }

  @keyframes circle__svg {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  .circle__svg-circle {
    animation: 2s ease-in-out infinite both circle__svg-circle;
    fill: transparent;
    stroke: #ffcc33;
    stroke-dasharray: 285;
    stroke-linecap: round;
    stroke-width: 5px;
    transform-origin: 50% 50%;
  }

  @keyframes circle__svg-circle {
    0% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`;
