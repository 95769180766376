import React from 'react';
import { Section, Paragraph, H2, IconLink, Icon } from '@lsg/components';

const FormSuccess = () => {
  return (
    <Section>
      <H2>Vielen Dank</H2>
      <Paragraph size={Paragraph.Sizes?.LEAD}>
        Wir werden Ihre Unterlagen schnellst möglich durchsehen und uns in Kürze
        bei Ihnen melden.
      </Paragraph>

      <IconLink to={'/'} iconName={Icon.Names.interaction_arrows_arrowRight}>
        Zurück zur Startseite
      </IconLink>
    </Section>
  );
};

export default FormSuccess;
